import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

import './plugins/bootstrap-vue';
import './plugins/custom-components';
import './plugins/filters';
import './plugins/font-awesome';
import './plugins/global-mixin';

Vue.prototype.$http = axios.create({ baseURL: '/api/' });
Vue.config.productionTip = false;

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
