<template>
	<div>
		<div class="bg-dark py-5 shadow-md text-center">
			<b-container>
				<b-row>
					<b-col md class="pb-4 pb-lg-0">
						<img :src="`${publicPath}images/swatplus-avatar.png`" alt="SWAT+ Logo" class="img-fluid" style="max-width:250px">
					</b-col>
					<b-col md class="mx-auto">
						<p class="lead text-white mx-auto" style="max-width:500px">
							SWAT+ is a completely revised version of the SWAT model. SWAT+ provides a more flexible spatial representation of interactions and processes within a watershed.
						</p>
						<p class="lead mb-0">
							<a href="https://swat.tamu.edu/software/plus/" class="btn btn-lg btn-primary">Download / Learn More</a>
						</p>
					</b-col>
				</b-row>
			</b-container>
		</div>

		<b-container class="py-5">
			<h2 class="mb-3">SWAT+ Interface Version Archive</h2>
			<p>
				The SWAT+ interface includes QSWAT+, SWAT+ Editor (with SWAT+ Check), and the model. 
				All versions are available in the table below, but we recommend installing 
				the most recent version and following the 
				<a href="https://swatplus.gitbook.io/docs/installation" target="_blank">installation instructions</a>.
				For more information about SWAT+, publications, and community tools, 
				<a href="https://swat.tamu.edu/software/plus/" target="_blank">click here</a>.
			</p>

			<page-container :page="page">
				<b-table-simple hover responsive class="border-bottom table-responsive">
					<b-thead head-variant="light">
						<b-tr>
							<b-th>Name</b-th>
							<b-th>Size</b-th>
							<b-th v-if="false">Downloads</b-th>
							<b-th>Date</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-if="currentPath.length > 0" class="bg-light">
							<b-td colspan="3">
								<span class="pointer text-muted" @click="popAndGet" title="Go back...">
									<font-awesome-icon :icon="['far', 'folder-open']" class="mr-2" fixed-width />
									{{currentPath.join('/')}}/...
								</span>
							</b-td>
						</b-tr>
						<b-tr v-for="(item, i) in data" :key="i">
							<b-td>
								<a v-if="item.isDirectory" href="#" @click.prevent="getFiles(item.name)">
									<font-awesome-icon :icon="['fas', 'folder']" class="mr-2" fixed-width />
									{{item.name}}
								</a>
								<a v-else :href="item.url" @click="fileClicked(item.url)">
									<font-awesome-icon :icon="['far', 'file-archive']" class="mr-2" fixed-width />
									{{item.name}}
								</a>
							</b-td>
							<b-td>
								<span v-if="!item.isDirectory">{{item.size}}</span>
							</b-td>
							<b-td v-if="false">
								<span v-if="!item.isDirectory">{{item.downloads}}</span>
							</b-td>
							<b-td>
								<span v-if="!item.isDirectory" class="text-muted">{{item.lastModified | date('D MMM YYYY, H:mm (Z)')}}</span>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</page-container>
		</b-container>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				page: {
					errors: [],
					loading: false
				},
				currentPath: [],
				data: []
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.post('home/files', []);
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getFiles(path) {
				if (!this.isNullOrEmpty(path)) this.currentPath.push(path);
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.post('home/files', this.currentPath);
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async popAndGet() {
				this.currentPath.pop();
				await this.getFiles('');
			},
			async fileClicked(url) {
				this.log(`Clicked ${url}`);
				let matches = this.data.filter(function (el) { return el.url == url; });
				if (matches.length === 1) matches[0].downloads++;

				try {
					const response = await this.$http.post('home/downloads', { value: url });
					this.log(response.data);
				} catch (error) {
					this.logError(error);
				}
			}
		}
	}
</script>
