<template>
	<div>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<error-list v-if="!showErrorsWithObject && page.errors.length > 0" :errors="page.errors"></error-list>
			<div v-else>
				<error-list v-if="showErrorsWithObject" :errors="page.errors"></error-list>
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PageContainer',
		props: {
			page: {
				type: Object,
				required: true
			},
			showErrorsWithObject: {
				type: Boolean,
				default: false
			}
		}
	}
</script>
