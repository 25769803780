import Vue from 'vue';
import moment from 'moment';

Vue.mixin({
	data() {
		return {
			publicPath: process.env.BASE_URL
		}
	},
	methods: {
		isDevMode() {
			return process.env.NODE_ENV === 'development';
		},
		isNullOrEmpty(value) {
			return value === undefined || value == null || value == '';
		},
		capitalizeFirstLette(s) {
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
		numberWithCommas(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		toDate(value, format = 'llll') {
			if (value) {
				return moment(String(value)).format(format);
			}
		},
		log(message) {
			if (this.isDevMode()) {
				console.log(message);
			}
		},
		logError(error, defaultMessage = undefined) {
			var messages = [];
			if (defaultMessage) {
				messages.push(defaultMessage);
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(error.response.data);
				//console.log(error.response.status);
				//console.log(error.response.headers);

				if (error.response.status === 401 || error.response.status === 403) {
					messages.push('Invalid credentials. Either your user name or password is incorrect, or you do not have permission to view this page.');
				} else if (error.response.status === 404) {
					messages.push('Requested data not found. Please check the URL in your browser and make sure you have the correct ID numbers if any.');
				} else if (error.response.data && error.response.data.errors) {
					var errors = error.response.data.errors;
					for (var key in errors) {
						for (var j = 0; j < errors[key].length; j++) {
							messages.push(errors[key][j]);
						}
					}
				} else if (error.response.data) {
					if (typeof error.response.data === 'string' || error.response.data instanceof String)
						messages.push(error.response.data);
					else
						messages.push('There was an error processing your request.');
				}

				if (error.response.data.stackTrace) {
					console.log(error.response.data.stackTrace);
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			//console.log(error.config);

			return messages;
		},
		errorContainsKey(error, key) {
			return error.response && error.response.data && error.response.data.errors && Object.prototype.hasOwnProperty.call(error.response.data.errors, key);
		}
	}
})