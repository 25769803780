<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<style lang="scss">
	@import 'app.scss';
</style>

<script>
	export default {
		name: 'app'
	}
</script>
